import React, { Suspense, useEffect } from 'react'

// PAGE TRANSITION =>   lazyPreload, pages??, routes??, 

const SuspenseTransitionFallback = React.memo(({ Component = <></>, onEnter = async () => { }, onChild = async () => { }, }) => {
    useEffect(() => {                                                                                               // ----------------------- METOD 1
        (async () => { await onEnter() })()             // ON ENTER
        return async () => { await onChild() }          // ON EXIT
    }, [])
    return Component
})

// EVENTS:  onEnter, onInit(mount), onEvent(event), onInput(load), onChild(loaded)
const SuspenseTransition = React.memo(({ Component, FallabackComponent, load, event, children, outside, onEnter, onInit, onEvent, onInput, onChild }) => {
    useEffect(() => {
        if (event || load !== undefined)
            (async () => {
                if (load && onInput) await onInput()                            // ON INPUT
            })()
    })
    useEffect(() => {
        if (onInit) (async () => { await onInit() })()                          // ON INIT
        if (event && onEvent) {  // 'PageLoaded'
            window.addEventListener(event, onEvent)                             // ON EVENT
            return () => window.removeEventListener(event, onEvent)
        }
    }, [])
    return <>
        {Component}
        {outside}
        <Suspense fallback={<SuspenseTransitionFallback component={FallabackComponent} onEnter={onEnter} onChild={onChild} />} > {children} </Suspense>
    </>
})
export default SuspenseTransition;

// ----- EVENTS BOILERPLATE
    // const onEnter = async () => { console.log("ON ENTER") }
    // const onInit = async () => { console.log("ON INIT") }
    // const onEvent = async () => { console.log("ON EVENT") }
    // const onInput = async () => { console.log("ON INPUT") }
    // const onChild = async () => { console.log("ON CHILD") }




// ----- TRANSITION COMPONENT => current {Component}
    // <TransitionComponent Component={Component} />
    // function TransitionComponent({ Component = <></>, load, onEnter, onExit, }) {   // 
    //     useEffect(() => { (async () => { if (load) await onEnter(); else await onExit() })() })                      // ----------------------- METOD 2
    //     return Component
    // }
