import React, { useRef } from 'react';



export default function Toggle({ inputRef, style, color = '#fff', toggleRotation = 0, scale = 1 }) {
    const svgRefs = (inputRef) ? inputRef : { cont: useRef(), left: useRef(), right: useRef() }



    return <svg viewBox="0 0 10 10" style={style} >
        <g fill={color} ref={svgRefs.cont} style={{ transformOrigin: 'center', transform: 'rotate(' + toggleRotation + 'deg) scale(' + (2 * scale) + ')' }}>
            <rect ref={svgRefs.left} x='25%' y='48%' width="50%" height="4%" style={{ transformOrigin: 'center', transform: 'rotate(45deg)' }} />
            <rect ref={svgRefs.right} x='25%' y='48%' width="50%" height="4%" style={{ transformOrigin: 'center', transform: 'rotate(135deg)' }} />
        </g>
    </svg>
}