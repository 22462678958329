import React, { useState } from 'react';
import { eventListener } from 'common'

// ----------------------------------------------------------------------------------------------------------------------------------------------------------
// ---------------------------- IMPORTS ----------------------------------------------------------------------
// ---- LOGO
import Logo from 'logo'
// ---- MENU
import { Transition as MenuTransitionIN } from 'design/transition/gsap/svg'
import { Toggle as MenuToggleIN } from 'design/toggle/gsap/1'
// ---- ICONS
import FacebookIcon from 'design/svg/icons/social-media/facebook-01.svg'
import InstagramIcon from 'design/svg/icons/social-media/instagram-01.svg'
// import { FontAwesome } from 'design/kit/fontAwesome'
// ----------------------------------------------------------------------------------------------------------------------------------------------------------
// ---------------------------- EXPORTS ----------------------------------------------------------------------
// ---- VIDEO
// const VideoConfig = false
// const VideoConfig = 'Firebase'   // 
// import VideoConfig from 'video/list.json'
import VideoConfig from 'video/cloudinary.json'
export { VideoConfig }
// console.log(VideoConfig)


// ---- LOGO
export const LogoImg = {
    extended: '/Logo Full - Shadow.png',
    compact: '/Icon Small.png', // "Icon Small.png"
    background: '',
    icon: ''
}
// ---- ICONS
export { FacebookIcon, InstagramIcon }              // ---- SOCIAL MEDIA
export { faAngleLeft as AngleLeft, faAngleRight as AngleRight } from '@fortawesome/free-solid-svg-icons'
// export { PlayBtn, PauseBtn, VolumeOff, VolumeDown, VolumeUp, VolumeMute, ExpandArrows, CompressArrows, VrCardboard } from 'design/kit/fontAwesome'       // ---- PLAYER UI
// ---- MENU
export { MenuList } from 'design/menu/1'
export const MenuTransition = (props) => <MenuTransitionIN {...props} />    // export const MenuTransition = 'page'     // <===== USE PAGE TRANSITION
export const MenuToggle = (props) => <MenuToggleIN {...props} />

// ---- FIRSTLOAD/RELOAD TRANSITION
const logoRef = React.createRef()
const firstLoadBgdRef = React.createRef()
const firstLoadTransitions = {}
export function FirstLoadInit() {    // firstLoad, setFirstLoad
    const FirstLoadComponent = () => {
        const [firstLoad, setFirstLoad] = useState(false); eventListener(window, 'FirstLoad', () => setFirstLoad(true), false, true)     // TRIGGER UPDATE
        //
        const FirstLoadBackground = <div ref={firstLoadBgdRef} className='firstLoad'
            style={{ position: 'absolute', top: 0, left: 0, height: '100vh', width: '100vw', background: 'var(--blackBackground)', overflow: 'hidden', zIndex: 6000 }} />
        const LogoComponent = <Logo inputRef={logoRef} firstLoad={firstLoad} logo={LogoImg} height='150px' width='35vw' minWidth='150px' mobilePx={600} zIndex={2001}
            fistLoadCenter center={[
                { path: 'firstLoad', x: 0, y: 0, height: '250px', width: '90vw', zIndex: 6001 },
                { path: '/', x: 0, y: 10, minScroll: 50, height: '250px', width: '90vw', zIndex: 20 }
            ]} />
        return <> {FirstLoadBackground} {LogoComponent} </>
    }
    function endFirstLoad() { window.firstLoad = true; window.dispatchEvent(new CustomEvent('FirstLoad')) }
    // function firstLoadOnGsap () { }
    firstLoadTransitions.start = () => { }         // NB: NO GSAP
    firstLoadTransitions.on = (duration = 0.5) => { return window.gsap.timeline().to(firstLoadBgdRef.current, { autoAlpha: 1, duration }); }
    firstLoadTransitions.off = (duration = 0.5) => { return window.gsap.timeline().to(firstLoadBgdRef.current, { autoAlpha: 0, duration }); }
    const FirstLoadEvents = {
        // onEnter: async () => { console.log("ON ENTER") },
        // onInit: async () => { console.log("ON INIT") },
        onEvent: async () => { if (!window.firstLoad) { await firstLoadTransitions.off(); endFirstLoad(); } },   // console.log("ON EVENT");
        // onInput: async () => { console.log("ON INPUT") },
        // onChild: async () => { console.log("ON CHILD") },
    }
    return [FirstLoadComponent, FirstLoadEvents]
}
// ----  BACK/FORWARD TRANSITION
export const BackForwardEvents = {
    onStart: async () => { await firstLoadTransitions.on(0) },
    onEnd: async () => { await firstLoadTransitions.off() },
}
// ---- PAGE TRANSITION
export { Transition as PageTransition } from 'design/transition/gsap/svg'
export const globalTransitions = {
    path: '*', from: '', to: '',
    onEnter: async () => {
        // await window.gsap.set('.page', { opacity: 0 })
        // window.gsap.to('.page', { opacity: 1, duration: 0.3, delay: 0.3, })
        console.log("-------- ENTER")
        await window.PageLoading
        console.log("-------- END ENTER")
        if (window.logging.page) console.log("PAGE TRANSITION OFF")
        await window.pageTransition.commands.off()
    },
    onLeave: async () => {
        await window.gsap.waitEndTimeline(window.menuTransition.timelines.on)               // WAIT END OPEN MENU
        // window.gsap.to('.page', { opacity: 0, duration: 0.5, delay: 0.1, })              // FADE
        if (window.menuTransition.commands.state) window.menuTransition.commands.off()      // CLOSE MENU
        await window.pageTransition.commands.on()
        // window.logo.center(true, { x: 0, y: 0, zIndex: 9999 })                           // CENTER LOGO !?!?!
        window.scrollTo(0, 0, 0);
    },
    preload: 'before',
    init: () => {
        // const pageOff = window.gsap.to('.page', { paused: true, opacity: 0, duration: 0.3, onComplete: () => console.log("AA") })
        // const pageOn = window.gsap.to('.page', { paused: true, opacity: 1, duration: 0.3 })
        // // ADD PAGE FADE OUT to PAGE TRANSITION
        // window.pageTransition.timelines.on.add(() => pageOff.restart(), 'on')
        // window.pageTransition.timelines.off.add(() => pageOn.restart(), 'off')
    }
}

// import PageTransition from 'design/transition/gsap/sequence'
// import { liquid0 as PageTransitionIMG } from 'design/transition/gsap/sequence/img'
// import PageTransition from 'design/transition/gsap/svg'


// ---- BLOG
// export { fetchBlogPost_STATIC as fetchBlogPost, fetchBlogList_STATIC as fetchBlogList } from 'blog/fetch'
// export { fetch_STATIC as fetchBlog } from 'common/react/fetch'
//          =>  inside blog/index.js    <Blog />

// ---- ECOMM
// window.ecomm = {products:[]}

// ---- CALENDAR
// export { fetch_STATIC as fetchCalendar } from 'common/react/fetch'
