import { useEffect } from 'react';  // , { Suspense, lazy }
import { useLocation, useHistory } from 'react-router-dom'

const TrailingSlash = ({ children }) => {
    const { pathname } = useLocation();
    const history = useHistory();
    useEffect(() => {
        if (pathname[pathname.length - 1] == '/' && pathname != '/') history.push(pathname.slice(0, -1))       // REDIRECT END TRAILING SLASH
    }, [])
    return children
}
export default TrailingSlash;

