import React, { useRef, useEffect } from 'react';

import { debounce, } from 'common'     // hexToRgb
import { OnOffTimelines } from 'common/gsap'

import gsap from 'gsap'     // GLOBAL 
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";
gsap.registerPlugin(MorphSVGPlugin); gsap.config({ force3D: true, });

// import './index.css'



// https://greensock.com/morphsvg/   https://greensock.com/docs/v3/Plugins/MorphSVGPlugin   https://codepen.io/GreenSock/pen/rOjeRq



// MORPH    type:'rotational'       =>  più realistico
// MORPH    external SVG    morph={MorphTargetSVG}
// INPUT SHAPE =>  text,blob,   =>      shape=>reference
// SHAPE ANIMATION
// SHAPES LIBRARY => BLOB, 
// MULTI INPUT SHAPE => STAGGER

// BLUR, OPACITY, SHADOW



// LEFT to CENTER => pos,scale center

// SHAPES => [{shape:'circle', inputMorph:'', targetMorph:'', position:'center'}]

// SHAPE=circle|rect|quad    POSITION=center|top|bottom|left|right|*combo*   ANGLEINTERSECT=true|false   LATERAL=true|false      MORPH=rect|undefined
export function Transition({ inputRef, className = '', zIndex = 1, style, commands = {}, timelines = {}, duration = 1, ease = 'expo.inOut', color = 'rgba(28, 32, 32, 0.85)', blur = '0px', opacity, shape = 'rect', shapes,
    targetMorph = 'rectAA', position = 'left', angleIntersect = true, lateral = true, shiftOrigin = true }) {
    //
    const ref = (inputRef) ? inputRef : { top: useRef(), middle: useRef(), bottom: useRef(), }

    // SHAPE POSITION
    let shapeCenter = { x: 50, y: 50 } // DEFAULT CENTER  
    if (position.includes('top')) shapeCenter.y = 0
    if (position.includes('bottom')) shapeCenter.y = 100
    if (position.includes('right')) shapeCenter.x = 100
    if (position.includes('left')) shapeCenter.x = 0


    // console.log(hexToRgb(color, opacity))

    useEffect(() => {
        const direction = (position == 'center') ? 'center' : (['right', 'left'].includes(position)) ? 'x' : (['top', 'bottom'].includes(position)) ? 'y'
            : (position.split(" ").length > 1) ? 'diagonal' : ''

        const fullLateral = (lateral) ? ((direction == 'y') ? 'y' : (direction == 'x') ? 'x' : false) : false
        const shapeRef = ref.middle.current, svgRef = ref.top.current
        let scale, screenSide

        // SHAPE POSITION
        let shapeCenter = { x: 50, y: 50 } // DEFAULT CENTER  
        if (position.includes('top')) shapeCenter.y = 0
        if (position.includes('bottom')) shapeCenter.y = 100
        if (position.includes('right')) shapeCenter.x = 100
        if (position.includes('left')) shapeCenter.x = 0

        // SET SCREEN SIDE          (rect => screenSide=undefined => ||'vw', ||'vh')
        if (['cirlce', 'quad'].includes(shape)) {
            if (direction == 'center') screenSide = 'vmax'      // SET SCREEN SIDE - CENTER
            else if (direction == 'y') screenSide = 'vh'        // SET SCREEN SIDE - LATERAL Y
            else if (direction == 'x') screenSide = 'vw'        // SET SCREEN SIDE - LATERAL X
            else if (direction == 'diagonal') screenSide = 'vh' // SET SCREEN SIDE - DIAGONAL
        }

        // UPDATE SCALE / CENTER SCREEN SIDE
        function getScreenSide(maj, res1, res2) { if (maj) return (window.innerHeight > window.innerWidth) ? res1 : res2; else (window.innerHeight < window.innerWidth) ? res1 : res2 }
        function circleRectInters(w, h, l) { return (Math.sqrt(w * w + h * h)) / l }
        function updateScale() {
            let multiplier
            if (shape == 'circle' && angleIntersect) {           // CIRCLE to ANGLES
                if (['x', 'y'].includes(direction)) { const lati = (direction == 'y') ? [window.innerWidth, window.innerHeight] : [window.innerHeight, window.innerWidth]; multiplier = circleRectInters(lati[0] / 2, lati[1], lati[1]) }
                else if (direction == 'diagonal') { const lati = [window.innerWidth, window.innerHeight]; multiplier = circleRectInters(lati[0], lati[1], lati[1]) }
                else if (direction == 'center') multiplier = circleRectInters(window.innerWidth, window.innerHeight, getScreenSide(true, window.innerHeight, window.innerWidth)) / 2
            } else {                        // SHAPE to BORDER
                if (direction == 'center') { multiplier = 0.5 }     // UPDATE - CENTER
                // else if (direction == 'diagonal') { }                                // UPDATE - DIAGONAL        ?????
                else multiplier = 1                                                     // UPDATE - LATERAL
            }
            scale = 100 * multiplier
        }; updateScale()


        function updateSvg() { gsap.set(svgRef, { xPercent: -50, yPercent: -50, height: (scale * 2) + (screenSide || 'vh'), width: (scale * 2) + (screenSide || 'vw'), }) }; updateSvg()
        function onResize() { updateScale(); updateSvg() }

        const initScale = (shape == 'circle') ? 50 : (['rect', 'quad'].includes(shape)) ? 100 : 1
        gsap.set(shapeRef, { x: 0, y: 0, scale: initScale })                                                    // PATH SCALE (match viewBox)
        gsap.set(svgRef, { scaleX: (fullLateral == 'y') ? 1 : 0, scaleY: (fullLateral == 'x') ? 1 : 0 })        // START SCALE 0

        // gsap.set(svgRef, { scale: 0.3 })    // TEST
        // MorphSVGPlugin.convertToPath(ref.middle.current)


        // DIRECTION
        const directionOn = {}      // { transformOrigin: 'left', }
        const directionOff = {}     // { transformOrigin: 'right', }

        // MORPH
        const morphOn = (targetMorph == 'rect') ? { morphSVG: this.ref.bottom } : {}
        const morphOff = (targetMorph == 'rect') ? { morphSVG: shapeRef } : {}

        // SHAPE'S ATTRIBUTES
        const attrOn = { scale: 1 }
        const attrOff = { scaleX: (fullLateral == 'y') ? 100 : 0, scaleY: (fullLateral == 'x') ? 100 : 0 }


        // TIMELINES
        const tlOn = gsap.timeline({ paused: true })
            .to(svgRef, { pointerEvents: 'auto', ...attrOn, ...morphOn, ...directionOn, duration, ease }, 'on')
        const tlOff = gsap.timeline({ paused: true })
            .to(svgRef, { pointerEvents: 'none', ...attrOff, ...morphOff, ...directionOff, duration, ease }, 'off')


        OnOffTimelines(tlOn, tlOff, commands, timelines)


        onResize = debounce(onResize, 1)
        window.addEventListener('resize', onResize)
        return () => { window.removeEventListener('resize', onResize) }
    }, [])




    const positionXY = { top: shapeCenter.y + '%', left: shapeCenter.x + '%', }
    return (
        <svg className={className} ref={ref.top} viewBox="0 0 100 100" preserveAspectRatio="none"
            style={{ position: 'absolute', pointerEvents: 'none', backdropFilter: 'blur(' + blur + ')', ...positionXY, zIndex, ...style, }} >

            {(shape == 'circle') ? circleShape(ref.middle, color)
                : (shape == 'rect') ? rectShape(ref.middle, color)
                    : <></>}
        </svg>
    )
}
// export default Page;


function rectShape(ref, color) { return <path ref={ref} fill={color} d="M1,0 C1,0.33333 1,0.66667 1,1 0.66667,1 0.33333,1 0,1 0,0.66667 0,0.33333 0,0 0.33333,0 0.66667,0 1,0 z" /> }
function circleShape(ref, color) { return <path ref={ref} fill={color} d="M2,1 C2,1.55228 1.55228,2 1,2 0.44772,2 0,1.55228 0,1 0,0.44772 0.44772,0 1,0 1.55228,0 2,0.44772 2,1 z" /> }
// const rect = <path d="M1,0 C1,0.33333 1,0.66667 1,1 0.66667,1 0.33333,1 0,1 0,0.66667 0,0.33333 0,0 0.33333,0 0.66667,0 1,0 z" />
// const circle = <path d="M2,1 C2,1.55228 1.55228,2 1,2 0.44772,2 0,1.55228 0,1 0,0.44772 0.44772,0 1,0 1.55228,0 2,0.44772 2,1 z" />





// ----- LAZY SHAPES
// const Circle = lazy(() => import('design/svg/shapes/circle'))
// const Rect = lazy(() => import('design/svg/shapes/rect'))
// {/* <Suspense fallback={() => <></>} >
//     {(shape == 'circle') ? <Circle inputRef={ref.middle} color={color} />
//         : (shape == 'rect') ? <Rect inputRef={ref.middle} color={color} />
//             : <></>}
// </Suspense> */}

// ----- OLD
// {/* {(shape == 'circle') ? <path ref={ref.middle} fill={color} d="M2,1 C2,1.55228 1.55228,2 1,2 0.44772,2 0,1.55228 0,1 0,0.44772 0.44772,0 1,0 1.55228,0 2,0.44772 2,1 z" />
// : (shape == 'rect') ? <path ref={ref.middle} fill={color} d="M1,0 C1,0.33333 1,0.66667 1,1 0.66667,1 0.33333,1 0,1 0,0.66667 0,0.33333 0,0 0.33333,0 0.66667,0 1,0 z" />
//     : <></>} */}


// ----- ORIGINAL SHAPES
// {/* <rect ref={ref.middle} x='0' y='0' width="1" height="1" fill={color} /> */ }
// {/* <ellipse ref={ref.middle} cx='1' cy='1' rx="1" ry="1" fill={color} /> */ }
// MorphSVGPlugin.convertToPath(ref.middle.current)


// ----- TEST
// {/* <svg ref={ref.top}></svg> */ }
// {/* <svg width='1vh' height='1vh' viewBox="0 0 100 100" preserveAspectRatio="none"></svg> */ }


// ----- ATTR ON OFF
// const attrOn =  (['cirlce', 'quad'].includes(shape)) ? { scale: 1 } : (shape == 'rect') ? {} : {}
// const attrOff = (['cirlce', 'quad'].includes(shape)) ? { scaleX: (fullLateral == 'y') ? 100 : 0, scaleY: (fullLateral == 'x') ? 100 : 0 } : (shape == 'rect') ? {} : {}
