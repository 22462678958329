// --------------- FOR IN ----------------  https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/for...in
// for (const property in object) {
//     console.log(`${property}: ${object[property]}`);
// }


export function jsonMap(json, func, obj = false) {
    return Object.keys(json).map((key, i) => func(key, json[key], i))
}

export function jsonForEach(json, func) {
    Object.keys(json).forEach((key, i) => func(key, json[key], i))
}

export function jsonFilter(json, func, excl) {
    const filtered = {}, exclused = {}
    jsonForEach(json, (key, i) => {
        if (func(key, json[key], i)) filtered[key] = json[key];
        else if (excl) exclused[key] = json[key]
    })
    return (excl) ? [filtered, exclused] : filtered
}


export function jsonFind(json, value) {
    return json[Object.keys(json).find((key, i) => json[key] == value)]
}