import React, { useState, useMemo } from 'react'
import SuspenseTransition from 'transition/suspense'
import { FirstLoadInit } from 'design-kit'   // FirstLoadComponent, FirstLoadEvents
// --------------------------------------------------------------------------------------------------------

// -------- TO DO
// COOKIE BANNER
// ON GSAP LOADED => ANIMATIONS     (App loaded, eventListener Dependencies,)

// --------------------------------------------------------------------------------------------------------
// document.addEventListener("DOMContentLoaded", () => { console.log("-------------- EVENT: DOMContentLoaded") });
// window.addEventListener('load', () => { console.log("-------------- EVENT: load") });


const FirstLoad = React.memo(({ children }) => {
    const [firstLoad, setFirstLoad] = useState(false)
    const [FirstLoadComponent, FirstLoadEvents] = FirstLoadInit(firstLoad, setFirstLoad)
    const Component = <FirstLoadComponent />
    return <SuspenseTransition event='PageLoaded' {...{ Component, ...FirstLoadEvents }} > {children} </SuspenseTransition>
})
export default FirstLoad;


// ----- EVENTS BOILERPLATE
    // const onEnter = async () => { console.log("ON ENTER") }
    // const onInit = async () => { console.log("ON INIT") }
    // const onEvent = async () => { console.log("ON EVENT") }
    // const onInput = async () => { console.log("ON INPUT") }
    // const onChild = async () => { console.log("ON CHILD") }

// ----- EVENTS BOILERPLATE
    // const FirstLoadEvents = {
    //     onEnter: async () => { console.log("ON ENTER") },
    //     onInit: async () => { console.log("ON INIT") },
    //     onEvent: async () => { console.log("ON EVENT") },
    //     onInput: async () => { console.log("ON INPUT") },
    //     onChild: async () => { console.log("ON CHILD") },
    // }

