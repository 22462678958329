import React, { useRef } from 'react';


// SCELTA STANGHETTE        =>  2/3


export default function Toggle({ inputRef, style, color = '#fff', toggleRotation = 0, scale = 1, lenght = [1, 1, 1], distance = 13, thick = 4 }) {
    const svgRefs = (inputRef) ? inputRef : { cont: useRef(), top: useRef(), middle: useRef(), bottom: useRef() }

    const strokeWidth = lenght.map(x => x * 50 + '%')
    const strokeX = lenght.map(x => 50 - x * 25 + '%')
    const strokeHeight = thick + '%'
    const strokeY = [0, 1, 2].map((i) => (50 + distance) - (i * distance) - (thick / 2) + '%')

    return <svg viewBox="0 0 10 10" style={style} >
        <g fill={color} ref={svgRefs.cont} style={{ transformOrigin: 'center', transform: 'rotate(' + toggleRotation + 'deg) scale(' + (2 * scale) + ')' }}>
            <rect ref={svgRefs.top} x={strokeX[0]} y={strokeY[0]} width={strokeWidth[0]} height={strokeHeight} />
            <rect ref={svgRefs.middle} x={strokeX[1]} y={strokeY[1]} width={strokeWidth[1]} height={strokeHeight} />
            <rect ref={svgRefs.bottom} x={strokeX[2]} y={strokeY[2]} width={strokeWidth[2]} height={strokeHeight} />
        </g>
    </svg>
}



// <rect ref={svgRefs.top} x='35%' y='61%' width="30%" height="4%" />
// <rect ref={svgRefs.middle} x='25%' y='48%' width="50%" height="4%" />
// <rect ref={svgRefs.bottom} x='35%' y='35%' width="30%" height="4%" />



// ---------------- FULL - ON OFF
// export default function Toggle({ inputRef, style, color = '#fff', toggleOnRotation, toggleOffRotation }) {
//     const svgRefs = (inputRef) ? inputRef : {
//         on: { cont: useRef(), top: useRef(), middle: useRef(), bottom: useRef() },
//         off: { cont: useRef(), left: useRef(), right: useRef() }
//     }

//     return (
//         <svg viewBox="0 0 10 10" style={style} >
//             <g fill={color}>
//                 <g ref={svgRefs.on.cont} style={{ transformOrigin: 'center', transform: 'rotate(' + toggleOnRotation + 'deg)' }}>
//                     <rect ref={svgRefs.on.top} x='35%' y='61%' width="30%" height="4%" />
//                     <rect ref={svgRefs.on.middle} x='25%' y='48%' width="50%" height="4%" />
//                     <rect ref={svgRefs.on.bottom} x='35%' y='35%' width="30%" height="4%" />
//                 </g>
//                 <g ref={svgRefs.off.cont} style={{ transformOrigin: 'center', transform: 'rotate(' + toggleOffRotation + 'deg)' }}>
//                     <rect ref={svgRefs.off.left} x='25%' y='48%' width="50%" height="4%" style={{ transformOrigin: 'center', transform: 'rotate(45deg)' }} />
//                     <rect ref={svgRefs.off.right} x='25%' y='48%' width="50%" height="4%" style={{ transformOrigin: 'center', transform: 'rotate(135deg)' }} />
//                 </g>
//             </g>
//         </svg>
//     )
// }