import React, { useRef, useEffect } from 'react';

import { OnOffTimelines } from 'common/gsap'
import SvgToggle1 from 'design/svg/toggle/hamburger'
import SvgToggle2 from 'design/svg/toggle/x'

// CSS COLOR
// @MEDIA SIZE
// DEBOUNCE, 


export function Toggle({ inputRef, className = '', commands = {}, timelines = {}, onClick = () => { },
    style, zIndex, position = 'top right', padding = 15, size = 50, color = '#fff',
    easeIn = 'Power4.easeIn', easeOut = 'Power4.easeOut' }) {

    const ref = (inputRef) ? inputRef : useRef()
    const svgRefs = { on: { cont: useRef(), top: useRef(), middle: useRef(), bottom: useRef() }, off: { cont: useRef(), left: useRef(), right: useRef() } }
    const btnRef = useRef()

    let containerPos = {}, toggleOnRotation, toggleOffRotation
    if (position.includes('left')) { containerPos.left = padding; toggleOnRotation = 135 }
    if (position.includes('right')) { containerPos.right = padding; toggleOnRotation = 45 }
    if (position.includes('top')) { containerPos.top = padding; toggleOnRotation *= -1; toggleOffRotation = 0 }
    if (position.includes('bottom')) { containerPos.bottom = padding; toggleOffRotation = 180 }


    useEffect(() => {
        // INIT
        window.gsap.set([svgRefs.off.left.current, svgRefs.off.right.current], { attr: { x: '-=100%' } })

        // ON TIMELINE
        const tlOn = window.gsap.timeline({ paused: true })
            .to([svgRefs.on.middle.current, svgRefs.on.top.current, svgRefs.on.bottom.current], { attr: { x: '+=100%' }, duration: 0.4, stagger: 0.1, ease: easeIn }, 'on')
            .to([svgRefs.off.left.current, svgRefs.off.right.current], { attr: { x: '+=100%' }, duration: 0.8, stagger: 0.2, ease: easeOut }, 'off')

        // OFF TIMELINE
        const tlOff = window.gsap.timeline({ paused: true })
            .to([svgRefs.off.left.current, svgRefs.off.right.current], { attr: { x: '-=100%' }, duration: 0.4, stagger: 0.1, ease: easeIn }, 'off')
            .to([svgRefs.on.middle.current, svgRefs.on.bottom.current, svgRefs.on.top.current], { attr: { x: '-=100%' }, duration: 0.8, stagger: 0.2, ease: easeOut }, 'on')

        OnOffTimelines(tlOn, tlOff, commands, timelines)

        // ON CLICK EVENT
        btnRef.current.addEventListener('click', onClick)

        return () => { btnRef.current.removeEventListener('click', onClick) }
    }, [])


    return (
        <div ref={ref} className={className + ' toggle-container'} style={{ position: 'absolute', ...containerPos, width: size, height: size, overflow: 'show', zIndex }}>

            <button ref={btnRef} className='toggle-btn reset-btn' style={{ position: 'absolute', width: '100%', height: '100%', ...style, }} />

            <svg style={{ width: '200%', height: '200%', position: 'relative', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', pointerEvents: 'none' }} >
                <SvgToggle1 inputRef={svgRefs.on} color={color} toggleRotation={toggleOnRotation} scale={0.5} lenght={[0.7, 1, 0.7]} />
                <SvgToggle2 inputRef={svgRefs.off} color={color} toggleRotation={toggleOffRotation} scale={0.5} />
            </svg>

        </div>
    )

}
// export default Toggle;



// -------- SVG INSIDE BUTTON
{/* <button ref={btnRef} className='toggle-button reset-btn'
    style={{ position: 'absolute', ...containerPos, zIndex: 1000, width: size, height: size, overflow: 'show', }}>
    <svg viewBox="0 0 1000 1000" style={{ width: '200%', height: '200%', position: 'relative', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', pointerEvents: 'none' }} >
        <g fill={color}>
            <g ref={svgRefs.on.cont} style={{ transformOrigin: 'center', transform: 'rotate(' + toggleOnRotation + 'deg)' }}>
                <rect ref={svgRefs.on.top} x='350' y='610' width="300" height="40" />
                <rect ref={svgRefs.on.middle} x='250' y='480' width="500" height="40" />
                <rect ref={svgRefs.on.bottom} x='350' y='350' width="300" height="40" />
            </g>
            <g ref={svgRefs.off.cont} style={{ transformOrigin: 'center', transform: 'rotate(' + toggleOffRotation + 'deg)' }}>
                <rect ref={svgRefs.off.left} x='250' y='480' width="500" height="40" style={{ transformOrigin: 'center', transform: 'rotate(45deg)' }} />
                <rect ref={svgRefs.off.right} x='250' y='480' width="500" height="40" style={{ transformOrigin: 'center', transform: 'rotate(135deg)' }} />
            </g>
        </g>
    </svg>
</button > */}



{/* <rect ref={svgRefs.on.top} x='250' y='480' width="500" height="60" fill={color} style={{ transformBox: 'fill-box', transformOrigin: 'center', transform: 'rotate(-45deg)' }} />
    <rect ref={svgRefs.on.top} x='410' y='450' width="100" height="20" fill={color} style={{ transformBox: 'fill-box', transformOrigin: 'center', transform: 'rotate(-45deg)' }} /> */}

{/* <line x1="0" x2="200" y1="0" y2="200" style={{ stroke: color, strokeWidth: 20 }} /> */ }




// ------------ SVG NOT IN COMPONENT
{/* <svg viewBox="0 0 10 10" style={{ width: '200%', height: '200%', position: 'relative', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', pointerEvents: 'none' }} >
                <g fill={color}>
                    <g ref={svgRefs.on.cont} style={{ transformOrigin: 'center', transform: 'rotate(' + toggleOnRotation + 'deg)' }}>
                        <rect ref={svgRefs.on.top} x='35%' y='61%' width="30%" height="4%" />
                        <rect ref={svgRefs.on.middle} x='25%' y='48%' width="50%" height="4%" />
                        <rect ref={svgRefs.on.bottom} x='35%' y='35%' width="30%" height="4%" />
                    </g>
                    <g ref={svgRefs.off.cont} style={{ transformOrigin: 'center', transform: 'rotate(' + toggleOffRotation + 'deg)' }}>
                        <rect ref={svgRefs.off.left} x='25%' y='48%' width="50%" height="4%" style={{ transformOrigin: 'center', transform: 'rotate(45deg)' }} />
                        <rect ref={svgRefs.off.right} x='25%' y='48%' width="50%" height="4%" style={{ transformOrigin: 'center', transform: 'rotate(135deg)' }} />
                    </g>
                </g>
            </svg> */}
