import React from 'react';
import { Link } from 'react-router-dom'

import { eventListener, disposeEventsList } from 'common'
import { OnOffTimelines } from 'common/gsap'

// NB: IL LOGO DEVE ESSERE DI DIMENSIONI SUPERIORI RISPETTO L'ALTEZZA CENTRALE PER ESSERE RESPONSIVE

class Logo extends React.Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef(); this.ref = (this.props.inputRef) ? this.props.inputRef : this.ref
        this.logoRefs = { link: React.createRef(), extended: React.createRef(), compact: React.createRef(), background: React.createRef(), icon: React.createRef() }
        this.disposeList = []
    }
    render() {
        const { height = '50px', width = '25vw' } = this.props      //'clamp(50px,15vw,150px)'
        const imgStyle = { position: 'absolute', margin: '5px 20px', borderRadius: '8px', width: 'auto', height: 'auto', maxHeight: height, maxWidth: width }  // aspectRatio: 'auto', height: '100%', width: 'auto',
        // 
        return <div className='logo'>
            <Link to='/' ref={this.logoRefs.link}>
                <div className='logo-container' ref={this.ref} style={{ userSelect: 'none', position: 'absolute', zIndex: (this.props.zIndex || 1001) }}>
                    {(!this.props.logo.background) ? null : <img ref={this.logoRefs.background} src={this.props.logo.background} alt={this.props.alt || "Logo"} style={imgStyle} />}
                    {(!this.props.logo.extended) ? null : <img id='button' ref={this.logoRefs.extended} src={this.props.logo.extended} alt={this.props.alt || "Logo"} style={imgStyle} />}
                    {(!this.props.logo.compact) ? null : <img id='button' ref={this.logoRefs.compact} src={this.props.logo.compact} alt={this.props.alt || "Logo"} style={imgStyle} />}
                </div>
            </Link>
        </div>
    }
    async componentDidUpdate() { if (this.props.firstLoad === true) this.afterFirstLoad() }                         // console.log("LOGO - UPDATE");
    componentDidMount() { if (this.props.firstLoad === false) this.onFirstLoad(); else this.afterFirstLoad() }      // console.log("LOGO - MOUNTED", window.firstLoad);
    onFirstLoad() {                                                                                                 // console.log("LOGO - ON FIRST LOAD")
        const LOGO = { container: this.ref.current, link: this.logoRefs.link.current, extended: this.logoRefs.extended.current, compact: this.logoRefs.compact.current, background: this.logoRefs.background.current }
        LOGO.all = []
        if (LOGO.extended) LOGO.all.push(LOGO.extended)
        if (LOGO.compact) LOGO.all.push(LOGO.compact)
        if (LOGO.background) LOGO.all.push(LOGO.background)

        const center = this.props.center.find(x => x.path == 'firstLoad')
        const { x = 0, y = 0, height = '300px', width = '90vw', zIndex } = center
        const firstLoadStyle = { maxHeight: height, maxWidth: width, transform: `translate(-${50 - x}%, -${50 - y}%) translate3d(50vw, 50vh, 0px)` }
        //
        LOGO.compact.style.opacity = 0;
        [LOGO.container, LOGO.link].forEach(logo => logo.style.pointerEvents = 'none')
        LOGO.container.style.zIndex = zIndex
        LOGO.all.forEach(logo =>
            Object.keys(firstLoadStyle).forEach(key =>
                logo.style[key] = firstLoadStyle[key]));
    }
    afterFirstLoad() {                                                                                              // console.log("LOGO - AFTER FIRST LOAD")
        const center = (Array.isArray(this.props.center)) ? this.props.center : [this.props.center]
        const { zIndex = 1001, height = '50px', width = '25vw', minWidth = '150px', mobilePx = 700 } = this.props, centerLogoPages = center.map(x => x.path)
        const LOGO = { container: this.ref.current, link: this.logoRefs.link.current, extended: this.logoRefs.extended.current, compact: this.logoRefs.compact.current, background: this.logoRefs.background.current }
        LOGO.all = [LOGO.extended, LOGO.compact, LOGO.background]

        // HOME CENTER LOGO - GET VALUES
        let centerLogo = { x: 0, y: 0, height: '120px', width: '90vw', minScroll: 50, zIndex: 2001 } // CAN CHANGE FOR EVERY PAGES
        function updateCenterLogo(input) {
            input = (Array.isArray(input)) ? input.find(x => x.path == window.location.pathname) : input
            if (!input) return
            const { minScroll = 50, height = '120px', width = '90vw', x = 0, y = 0, zIndex } = input
            centerLogo = { x, y, height, width, minScroll, zIndex }
        }

        // HOME CENTER LOGO - TIMELINES
        let logoCentered = false //centerLogoPages.includes(window.location.pathname)
        const attrs = { overwrite: 'auto', duration: 0.7, ease: 'Power2.easeInOut' }   // , clearProps: 'maxWidth,maxHeight,transform'
        const centerLogoON = window.gsap.timeline({ paused: true })
            .set([LOGO.container, LOGO.link], { pointerEvents: 'none' }, 'on')
            .to(LOGO.all, { x: () => 50 - centerLogo.x + 'vw', y: () => 50 - centerLogo.y + 'vh', yPercent: - 50, xPercent: - 50, minWidth, maxHeight: () => centerLogo.height, maxWidth: () => centerLogo.width, ...attrs }, 'on')
            .set(LOGO.container, { zIndex: () => centerLogo.zIndex, }, 'on')
        const centerLogoOFF = window.gsap.timeline({ paused: true })
            .set([LOGO.container, LOGO.link], { pointerEvents: 'all' }, 'off')
            .to(LOGO.all, { x: '0vw', y: '0vh', yPercent: 0, xPercent: 0, maxHeight: height, minWidth, maxWidth: width, ...attrs }, 'off')
            .set(LOGO.container, { zIndex }, 'off')
        const commands = OnOffTimelines(centerLogoON, centerLogoOFF)        // ADD INVALIDATE => (centerLogoON, centerLogoOFF, true)
        function logoCenterON() { centerLogoON.invalidate(); commands.on(); logoCentered = true }
        function logoCenterOFF() { centerLogoOFF.invalidate(); commands.off(); logoCentered = false }

        // GLOBAL   // DA FINIRE !!!!!!!!
        function logoCenterGLOBAL(onOff = false, options = centerLogo) { // { minScroll, height, width, offset:{x,y}, zIndex}
            if (options != centerLogo) jsonForEach(centerLogo, (key) => { options[key] = options[key] || centerLogo[key] })    // USE CURRENT IF NOT SPECIFIED
            if (onOff) { updateCenterLogo(options); logoCenterON() }
            else { updateCenterLogo(center); onScroll() }
        }
        window.logo = { center: logoCenterGLOBAL }   // { on: logoCenterON, off: logoCenterOFF }    // GLOBAL CENTER LOGO

        // CHANGE LOGO EXTENDED/COMPACT - TIMELINES & EVENTS
        const duration = 0.3; let logoSize
        const changeLogoEXT = window.gsap.timeline({ overwrite: 'auto', paused: true })
            .to(LOGO.compact, { scale: 0, autoAlpha: 0, duration }, 'A')
            .to(LOGO.extended, { scale: 1, autoAlpha: 1, duration }, 'A')                           // NB: NON SCALA !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        const changeLogoCMP = window.gsap.timeline({ overwrite: 'auto', paused: true })
            .to(LOGO.extended, { scale: 0, autoAlpha: 0, duration }, 'A')
            .to(LOGO.compact, { scale: 1, autoAlpha: 1, duration }, 'A')
        const commands2 = OnOffTimelines(changeLogoEXT, changeLogoCMP)
        function logoSizeEXT() { changeLogoEXT.invalidate(); commands2.on(); logoSize = 'EXT' }
        function logoSizeCMP() { changeLogoCMP.invalidate(); commands2.off(); logoSize = 'CMP' }
        const onResize = () => {
            if (window.innerWidth < mobilePx) { if (logoSize != 'CMP' && !logoCentered) logoSizeCMP() }
            else if (logoSize != 'EXT') logoSizeEXT()
        }
        this.disposeList.push(eventListener(window, 'resize', onResize, false, false))

        // HOME CENTER LOGO - EVENTS
        const isInCenterPages = () => (centerLogoPages.includes(window.location.pathname)) ? true : false
        const onScroll = () => {
            if (isInCenterPages())
                if (!logoCentered && window.scrollY < centerLogo.minScroll) {
                    if (window.innerWidth < mobilePx) logoSizeEXT()           // CENTERED EXTENDED
                    logoCenterON()
                }
                else if (logoCentered && window.scrollY >= centerLogo.minScroll) {
                    if (window.innerWidth < mobilePx) logoSizeCMP()           // RETURN TO COMPACT
                    logoCenterOFF()
                }
        }
        this.disposeList.push(eventListener(window, 'scroll', onScroll, false, false))

        // CHANGE PAGE
        const onPageRefresh = () => {
            updateCenterLogo(center);
            if (!isInCenterPages() && logoCentered) {
                logoCenterOFF();
                if (window.innerWidth < mobilePx) logoSizeCMP()           // RETURN TO COMPACT
            }
            else onScroll();
        }
        this.disposeList.push(eventListener(window, 'PageRefresh', onPageRefresh, false, false))

        // INIT
        if (centerLogoPages.includes(window.location.pathname)) updateCenterLogo(center)
        onScroll()  // HOME CENTER LOGO
        onResize()  // CHANGE LOGO EXTENDED/COMPACT
        if (this.props.fistLoadCenter && !isInCenterPages()) logoCenterOFF()
    }
    componentWillUnmount() {
        disposeEventsList(this.disposeList)
    }
}
export default Logo;


// ASPECT RATIO
// const aspectRatio = this.ref.current.clientHeight / this.ref.current.clientWidth
// console.log(aspectRatio)
// this.ref.current.style.height = '100%'
// console.log(this.ref.current.clientHeight / this.ref.current.clientWidth)



// ------- RESET FIRST LOAD
    // this.resetFirstLoad();
    // resetFirstLoad() {
    //     console.log("LOGO - RESET FIRST LOAD")
    //     const LOGO = { container: this.ref.current, link: this.logoRefs.link.current, extended: this.logoRefs.extended.current, compact: this.logoRefs.compact.current, background: this.logoRefs.background.current }
    //     LOGO.all = [LOGO.extended, LOGO.compact, LOGO.background]
    //     LOGO.compact.style.opacity = undefined;
    //     [LOGO.container, LOGO.link].forEach(logo => logo.style.pointerEvents = undefined)
    //     //
    //     // const { zIndex = 1001, height = '50px', width = '25vw', minWidth = '150px' } = this.props
    //     // window.gsap.set(LOGO.all, { x: '0vw', y: '0vh', yPercent: 0, xPercent: 0, maxHeight: height, minWidth, maxWidth: width })
    //     // window.gsap.set(LOGO.container, { zIndex })
    // }

// ------- TRANSITION: FIRSTLOAD=>CENTER/TOP
    // this.transitionFristLoad();
    // async transitionFristLoad() {     // TRANSITION       FIRSTLOAD>CENTER / FIRSTLOAD>TOP
    //     const LOGO = { container: this.ref.current, link: this.logoRefs.link.current, extended: this.logoRefs.extended.current, compact: this.logoRefs.compact.current, background: this.logoRefs.background.current }
    //     LOGO.all = [LOGO.extended, LOGO.compact, LOGO.background]
    //     const center = (Array.isArray(this.props.center)) ? this.props.center : [this.props.center]
    //     const centerProps = center.find(x => x.path == window.location.pathname)
    //     if (centerProps) {
    //         console.log("TRANSITION CENTER")
    //         // const { zIndex = 1001, height = '50px', width = '25vw', minWidth = '150px' } = centerProps
    //         // window.gsap.to(LOGO.all, { overwrite: 'auto', x: '0vw', y: '0vh', yPercent: 0, xPercent: 0, maxHeight: height, minWidth, maxWidth: width })
    //         // window.gsap.to(LOGO.container, { zIndex })
    //     }
    //     else {
    //         console.log("TRANSITION TOP")
    //         // const { zIndex = 1001, height = '50px', width = '25vw', minWidth = '150px' } = this.props
    //         // window.gsap.set(LOGO.all, { x: '0vw', y: '0vh', yPercent: 0, xPercent: 0, maxHeight: height, minWidth, maxWidth: width })
    //         // window.gsap.set(LOGO.container, { zIndex })
    //     }
    // }
