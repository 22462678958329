import { globalImport } from 'common'
import gsap from 'gsap'


// ---------- UTILITY
export function globalGSAP(config, plugins = {}) {
    window.gsap = gsap;                                 // GLOBAL GSAP
    window.gsap.config(config)
    window.gsapTimelines = gsap.timeline()       // GSAP TIMELINES ARRAY
    jsonForEach(plugins, (name, plugin) => { window[name] = plugin; gsap.registerPlugin(plugin) })  // GLOBAL & REGISTER
}
export async function gsapGlobalImport(name, importCb, register = true) {
    const check = (register && !window[name]) ? true : false
    await globalImport(name, importCb)
    if (check) gsap.registerPlugin(window[name])
}
export function disposeTrigger(trigger) {   // ScrollTrigger.dispose()
    trigger = (Array.isArray(trigger)) ? trigger : [trigger]
    trigger.forEach(element => { window.ScrollTrigger.getById(element.className).kill() })
}

// ---------- EXPAND GSAP
export function gsapExpand(list) {
    const GSAP_TIMELINE = gsap.timeline

    function newTimeline(options) {
        const timeline = GSAP_TIMELINE(options)
        Object.keys(list).forEach(key => { timeline[key] = list[key](timeline, list) })
        timeline.empty = (duration = 0.001, position) => timeline.to('body', (typeof duration == 'object') ? duration : { duration }, position)
        return timeline
    }
    gsap.timeline = newTimeline
}


// ---------- TIMELINE
async function waitEndTimeline(tl) { if (tl.isActive()) await sleep((tl.duration() - tl.totalTime()) * 1000) }
gsap.waitEndTimeline = waitEndTimeline

export function OnOffTimelines(tlOn, tlOff, commands = {}, timelines = {}) {
    timelines.on = tlOn; timelines.off = tlOff
    commands.on = async () => {
        // if (timelines.off == 'reverse') { if (timelines.on.progress() > 0) await waitEndTimeline(timelines.on) }     // WAIT OTHER TL to COMPLETE
        // else { if (timelines.off.progress() > 0) await waitEndTimeline(timelines.off) }                              // WAIT OTHER TL to COMPLETE

        /// console.log("ON", timelines.on.progress())

        if (timelines.on.progress() < 1) await timelines.on.play();
        else await timelines.on.restart();
        commands.state = true
    }
    commands.off = async () => {
        // if (timelines.on.progress() > 0) await waitEndTimeline(timelines.on)                                         // WAIT OTHER TL to COMPLETE

        // console.log("OFF", timelines.on.progress())

        if (timelines.off == 'reverse') timelines.on.reverse()
        else
            if (timelines.off.progress() < 1) await timelines.off.play();
            else await timelines.off.restart();
        commands.state = false
    }
    commands.toggle = async () => { if (commands.state) await commands.off(); else await commands.on() }
    // commands.reset = async () => {
    //     // timelines.on  .totalProgress(0)   .totalTime(0)                 // => set to start value
    //     // timelines.off.play();     // DURATION => 0
    // }

    return commands
}







// ------- GSAP JSON TIMELINE
// const gsapTimelineJSON_example = {
//     selectors: { a: '.selector1', b: 'selector2' },
//     vars: { paused: true },
//     0: { func: 'to', elements: ['a'], to: {}, position: 'pos1' },
//     1: { func: 'from', elements: ['a', 'b'], from: {}, position: 'pos2' },
//     2: { func: 'fromTo', elements: ['b'], from: {}, to: {}, position: 'pos3' },
// }
// export function gsapTimelineJSON(json, parentElement) {
//     const selectors = {}; Object.keys(json.selectors).forEach(key => selectors[key] = parentElement.querySelector(json.selectors[key]))
//     const tl = window.gsap.timeline(json.vars)
//     const funcNum = (Object.keys(json).filter(x => parseInt(x) != NaN)).length
//     for (let i = 0; i < funcNum; i++) {
//         const func = json[i]
//         const elements = func.elements.map(x => selectors[x])
//         if (func.func == 'fromTo') tl.fromTo(elements, func.from, func.to, func.position)
//         else tl[func.func](elements, func[func.func], func.position)
//     }
//     return tl
// }






// const commands = (inputCommands) ? inputCommands : { on: useRef(), off: useRef(), }
// const timelines = (inputTimelines) ? inputTimelines : { on: useRef(), off: useRef() }

// const commands = (inputCommands) ? inputCommands : { on: null, off: null, toggle: null, state: false }
// const timelines = (inputTimelines) ? inputTimelines : { on: null, off: null }
