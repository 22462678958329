import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom'

import { OnOffTimelines } from 'common/gsap'

import './index.css'


export function MenuList({ inputRef, style, zIndex, commands = {}, timelines = {}, list, duration = 0.3, easeIn = 'Power0.easeIn', easeOut = 'Power0.easeOut', }) {
    const ref = (inputRef) ? inputRef : useRef()
    useEffect(() => {
        const menuItems = ref.current.querySelectorAll('.menu-list > .menu-item')
        // INIT
        window.gsap.set(menuItems, { autoAlpha: 0, x: -50 })
        //ON TIMELINE
        const tlOn = window.gsap.timeline({ paused: true })
            .to(menuItems, { autoAlpha: 1, x: 0, duration, ease: easeIn, stagger: 0.1 })
        //OFF TIMELINE
        const tlOff = window.gsap.timeline({ paused: true })
            .to(menuItems, { autoAlpha: 0, x: -50, duration, ease: easeOut, stagger: 0.1 })
        OnOffTimelines(tlOn, tlOff, commands, timelines)    // 'reverse'
        // commands.on().then(e => { console.log("reverse"); commands.off(); })
    }, [])
    function menuList() {
        return list.map(element => (
            <li className='menu-item' key={element[0]}><Link className="menu-link" to={element[1]}>{element[0]}</Link>
                {subMenuList(element)} </li>))
    }
    function subMenuList(element) {
        return (!element[2]) ? null :
            <ul className='submenu'>{element[2].map(subElem =>
                (<li className='menu-item' key={subElem[0]}><Link className="menu-link" to={subElem[1]}>{subElem[0]}</Link></li>))}
            </ul>
    }
    return <ul ref={ref} className="menu-list" style={{ zIndex, ...style, }}> {menuList()} </ul>
}
// export default MenuList;


