import React, { lazy } from 'react';  // , { Suspense, lazy }
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom'

import './font.js'
import './reset.css'
import './index.css'

import FirstLoad from 'transition/suspense/firstLoad'
import TrailingSlash from 'common/react/trailingSlash'
const App = lazy(() => import('App'))
const createRoot = ReactDOM.createRoot || ReactDOM.unstable_createRoot          // CONCURRENT MODE

window.logging = { page: false, video: false, }

window.rDOM = ReactDOM

createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <Router>
            <TrailingSlash>
                <FirstLoad>
                    <App />
                </FirstLoad>
            </TrailingSlash>
        </Router>
    </React.StrictMode>
)

// ---- FirstLoad
// Background Color
// Load Logo Full 
// Check DownloadSpeed,CPU,GPU
// Load =>      FullWebsite || SimpleWebsite    => NO:gsap,three,video   => window.gsap=dummyGsap   =>  nativeScroll,scrollBar




if (window.logging.page) document.addEventListener("DOMContentLoaded", () => { console.log("-------------- EVENT: DOMContentLoaded") });
if (window.logging.page) window.addEventListener('load', () => { console.log("-------------- EVENT: load") });




// DIRECT RENDER APP - NO LAZY
    // import App from './App';
    // createRoot(document.getElementById("root")).render(<React.StrictMode> <App /> </React.StrictMode>)

// UNSTABLE CREATE ROOT
    // ReactDOM.createRoot(document.getElementById("root")).render(<React.StrictMode> <App /> </React.StrictMode>);
    // ReactDOM.unstable_createRoot(document.getElementById("root")).render(<React.StrictMode> <App /> </React.StrictMode>);

// DEFAULT MODE
    // ReactDOM.render(<React.StrictMode> <App /> </React.StrictMode>, document.getElementById('root'));
